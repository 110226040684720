import React, { useState } from 'react';
import { Card } from '../Card';
import { useAppContext } from '../../contexts/AppContext';
import styled from '@emotion/styled';
import { Box, Button, useTheme } from '@mui/material';
import { FEATURE_GRID_A_CARD_BUTTON_STYLE } from '../../types/static';
import { setDefaultStyleValues } from '../../utils/set-default-style-values';
import { Card as MuiCard } from '@mui/material';
import { CustomButton } from '../CustomButton';
import { Link } from '../Link';
import { Title } from '../Title';

export interface CollectionCommonCProps {
  template: string;
  items: any;
  component?: any;
  type?: any;
  slug?: any;
  name?: any;
}

const ProductSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: '1fr 1fr',
  gap: theme.spacing(3),
  padding: 0,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const CardStyled = styled(Card)(({ theme, template, hoverEffect, cardBorder }: any) => ({
  width: '100%',
  // height: '100%',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : `${theme.theme_component_card_border_radius}px` || theme.shape.borderRadius,
  border: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
}));

const ProductItem = styled(CardStyled)(({ theme }) => ({
  gridColumn: '1 / span 2',
  gridRow: '1 / span 2',
}));

const MoreButton = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  padding: '10px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  textAlign: 'center',
  marginTop: '10px',
  fontSize: theme.spacing(3),
}));



export const FeatureGridA = (props: CollectionCommonCProps) => {
  const theme = useTheme();
  const { items = [], template, component } = props;
  const [showAll, setShowAll] = useState(false);
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();

  const visibleItems = showAll ? items : items.slice(0, 3);

  console.log("th123eme",theme);

  return (
    <Box mb={5}>
      {!props?.component?.hideTitle && (
        <Link
          href={`/${props.type}/${props.slug}`}
          title={props.name || undefined}
          underline="none"
        >
          <Title variant={'h2'}>
            {props.name}
          </Title>
        </Link>
      )}
      <ProductSection>
        {visibleItems.map((game: any, index: number) => {
          if (index === 0) {
            return (
              <ProductItem
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                src={`${assetUrl}${
                  [1, 4, 7].includes(index)
                    ? game.images.cover16x9 || game.images.cover
                    : game.images.cover16x9 || game.images.cover
                }/`}
                description={game.description || ''}
                alt={game.name}
                title={game.name || undefined}
                sx={{ display: 'flex' }}
                template={template}
                width={700}
                height={550}
                layout="responsive"
                hideDescription={component?.hideDescription}
                configurationStyle={setDefaultStyleValues(FEATURE_GRID_A_CARD_BUTTON_STYLE, theme)}
              />
            );
          } else {
            return (
              <CardStyled
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                src={`${assetUrl}${
                  [1, 4, 7].includes(index)
                    ? game.images.cover4x3 || game.images.cover
                    : game.images.cover16x9 || game.images.cover
                }/`}
                alt={game.name}
                sx={{ display: 'flex' }}
                template={template}
                width={335}
                height={315}
                layout="responsive"
                hideDescription={component?.hideDescription}
                configurationStyle={setDefaultStyleValues(FEATURE_GRID_A_CARD_BUTTON_STYLE, theme)}
              />
            );
          }
        })}
      </ProductSection>
      {!showAll && items.length > 3 && (
        <MoreButton>
          <CustomButton color="primary" variant="contained" onClick={() => setShowAll(true)}>
            More Games
          </CustomButton>
        </MoreButton>
      )}
    </Box>
  );
};
