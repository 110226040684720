interface StyleItem {
  name: string;
  default: any;
}

interface ThemeStyles {
  palette: {
    text: { primary: string };
    common: { white: string };
    action: { hover: string; active: string };
  };
}

interface StyleDefaults {
  [key: string]: string | number;
}

export const setDefaultStyleValues = (styles: StyleItem[], theme: ThemeStyles): StyleItem[] => {
  const styleDefaultValues = getStyleDefaultValues(theme);

  const newStyles = styles.map((style) => {
    const key = Object.keys(styleDefaultValues).find((s) => style.name.includes(s));
    if (key && styleDefaultValues[key]) {
      return { ...style, default: styleDefaultValues[key] };
    }
    return style;
  });
  return newStyles;
};

const getStyleDefaultValues = (theme: ThemeStyles): StyleDefaults => ({
  card_button_color: theme.palette.text.primary,
  card_button_background: theme.palette.common.white,
  card_button_hover_background_color: theme.palette.action.hover,
  card_button_active_background_color: theme.palette.action.active,
  card_button_font_weight: 'regular',
  card_button_font_size: 16,
  card_button_border_radius: 16,
});
